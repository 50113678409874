@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000000;
  color: white;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-dots li.slick-active button:before {
  color:orange !important;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}

.coach-home-linear-gradient {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/CHeroHome.jpg');
  background-size: cover;
  background-position: center;
}

.hero-career-mobile-linear-gradient {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/CHeroCareerM.jpg');
  background-size: cover;
  background-position: top;
}

.hero-career-linear-gradient {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/CHeroCareer.jpg');
  background-size: cover;
  background-position: center;
}

.coach-home-coach-linear-gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.50) 100%), url('https://d3hde7ibm2i7sg.cloudfront.net/CCoach.jpg'), lightgray;
  background-size: cover;
  background-position: center;
}

.coach-home-coach-mobile-linear-gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.50) 100%), url('https://d3hde7ibm2i7sg.cloudfront.net/CCoachesM.jpg'), lightgray;
  background-size: cover;
  background-position: center;
}

.hero-home-linear-gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.50) 100%), url('https://d3hde7ibm2i7sg.cloudfront.net/CHeroImageM.jpg'), lightgray;
  background-size: cover;
  background-position: center;
}

.video-responsive {
  overflow: hidden;
  border: 0px;
}

/* .swiper-button-next, .swiper-button-prev {
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.swiper-button-disabled {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;
} */

@media (max-width: 700px) {
  div.hide-button-on-small > div.swiper-button-next, div.hide-button-on-small > div.swiper-button-prev {
    visibility: hidden;
  }
}

/* @media (min-width: 700px) {
  div.outer-arrow-success-home > div.swiper-button-next{
    position: absolute;
    top: 50%;
    right: -40px;
    width: 45px;
    height: 45px;
    transform: translateY(-50%);
  }
}

@media (min-width: 700px) {
  div.outer-arrow-success-home > div.swiper-wrapper {
    position: relative;
  }
}

@media (min-width: 700px) {
  div.outer-arrow-success-home > div.swiper-button-prev {
    position: absolute;
    top: 50%;
    left: -40px;
    width: 45px;
    height: 45px;
    transform: translateY(-50%);
  }
} */

.swiper-button-next, .swiper-button-prev {
  color: #fff !important
}

.custom-strike {
  text-decoration: none;
  position: relative;
}

.custom-strike:after {
  content: '';
  display: block;
  width: 100%;
  height: 55%;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 0.18rem solid;
}

div.pricing-custom-swiper > div.swiper-wrapper {
  align-items: end;
}

.custom-linear-gradient-navbar {
  background: linear-gradient(180deg, #1C1C1C 0%, rgba(28, 28, 28, 0.00) 100%), #000;
}

.career-card-1 {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/career-page-assets/CPlayerDevelopmentM.png');
  background-position: center;
  background-size: cover;
}

.career-card-2 {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/career-page-assets/CProfessionalCareerM.png');
  background-position: center;
  background-size: cover;
}

.age-group-card-rookie {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/journey-assets/CRookieM.png');
  background-position: center;
  background-size: cover;
}

.age-group-card-rookie-desktop {
  backround: url('https://d3hde7ibm2i7sg.cloudfront.net/journey-assets/journey-desktop-assets/CRookie.png');
  background-position: center;
  background-size: cover;
}

.age-group-card-inter {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/journey-assets/CIntermediateM.png');
  background-position: center;
  background-size: cover;
}

.age-group-card-elite {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/journey-assets/CEliteM.png');
  background-position: center;
  background-size: cover;
}

.vision-mission-linear-gradient {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/about-page-assets/CVisionMissionM.jpg');
  background-position: center;
  background-size: cover;
}

/* .founder-background {
  background: linear-gradient(180deg, rgba(28, 28, 28, 0.00) 0%, #030303 100%);
} */

.player-card-home {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/home-page-assets/PCM.png');
  backround-position: center;
  background-size: cover;
}

.swiper-pagination-bullet {
  background-color: #fff !important;
}

.founder-background-desktop {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/about-page-assets/about-desktop-assets/CFounder.jpg');
  backround-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-contact-linear-gradient {
  background: url('https://d3hde7ibm2i7sg.cloudfront.net/CHeroContact.jpg');
  background-size: cover;
  background-position: center;
}

.swiper-custom-pagination {
  padding-top: 25px;
}

@media (min-width: 1000px) {
  .swiper-custom-pagination {
    padding-top: 50px;
  }
}

div.testimonial-custom-swiper > div.swiper-wrapper {
  align-items: center !important;
}

div.testimonial-custom-swiper > div.swiper-wrapper > div.swiper-slide {
  height: auto !important;
}

.coach-centering {
  whitespace: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

.coach-centering-subtitle {
  position: absolute;
  bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 56.25rem;
}

@media (min-width: 1000px) {
  .coach-centering-subtitle {
    whitespace: nowrap;
    left: 50%;
    transform: translateX(-50%);
    width: 56.25rem;
  }
}

.black-overlay-text {
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
}